import { get2, post } from "./request";

/**
 * 取得客戶ＩＰ
 */

const getIP = () => {
  return get2("/getip.aspx", "");
};
/**
 * Post API
 */
const apiPost = async (action, dist) => {
  var res = await post(apiurl(action), xmlfile(action, dist));
  var data = formart(res);
  return new Promise((resolve) => {
    resolve(data);
  });
};

const xmlfile = (action, data) => {
  var xml = "";
  if (action == "rebate1") {
    action = "rebate";
  }
  xml =
    "<?xml version='1.0' encoding='UTF-8'?><request action='" +
    action +
    "'><element>";
  for (var key in data) {
    xml += "<properties name='" + key + "'>" + data[key] + "</properties>";
  }
  const lang = localStorage.getItem("locale") || process.env.VUE_APP_LANG;

  xml += "<properties name='lang'>" + lang + "</properties>";

  xml +=
    "<properties name='station'>" +
    process.env.VUE_APP_STATOIN +
    "</properties>";
  xml += "</element></request>";
  return xml;
};

const formart = (data) => {
  try {
    var str = data.replace("\r\n\r\n", "").split("<!")[0];
    return str;
  } catch {
    return data;
  }
};

const apiurl = (action) => {
  var url;
  switch (action) {
    case "Login":
    case "Register":
    case "Set_Register":
    case "RegSMS":
    case "Set_Logo":
    case "Check_userid":
      url = "/app1.aspx";
      break;
    case "share_info":
    case "share_default":
    case "status":
    case "commission":
    case "report":
    case "rebate1":
    case "cashback":
    case "vip_follows":
    case "utype":
    case "vip_list":
      url = "/APP_api/utype.aspx";
      break;
    case "rebate_bet":
      url = "/APP_api/rebate_APP_api.aspx";
      break;
    case "sign_up":
      url = "/APP_api/sign_up.aspx";
      break;
    case "message1":
    case "message2":
    case "message_delete":
      url = "/APP_api/message1_APP_api.aspx";
      break;
    case "invite":
    case "statistic":
    case "salary":
    case "invited_person":
    case "bonus":
    case "promotions":
      url = "/APP_api/bonus_APP_api.aspx";
      break;
    case "withdraw1":
    case "withdraw2":
      url = "/APP_api/withdraw_APP_api.aspx";
      break;
    case "set_logo":
    case "Member_unlogin":
      url = "/APP_api/Member_APP_api.aspx";
      break;
    case "wheel":
      url = "/APP_api/wheel.aspx";
      break;
    case "extra_bonus":
      url = "/APP_api/extra_bonus.aspx";
      break;
    case "sharebonus_item":
    case "sharebonus_buy":
    case "sharebonus_holding":
      url = "/APP_api/sharebonus.aspx";
      break;
    case "etf_item":
    case "etf_buy":
    case "etf_holding":
    case "etf_sale":
      url = "/APP_api/etf.aspx";
      break;
    case "mybet_kind":
      url = "/APP_api/mybet_APP_api.aspx";
      break;
    case "ChangeWithdrawPassword":
      url = "/APP_api/ChangePassword_APP_api.aspx";
      break;
    case "banksubmit":
      url = "/APP_api/bankchoose_APP_api.aspx";
      break;
    // case "create_club":
    case "myclub":
    case "all_table":
    case "myclub_vip":
    case "create_club_vip":
    case "buy_club_coin":
    case "create_table":
    case "check_club":
    case "create_club_member":
    case "all_club_member":
    case "check_member":
    case "club_buy_coin":
    case "coin_adjust":
    case "coin_transation":
    case "coin_manage_list":
    case "coin_manage":
    case "data":
    case "dalidata":
    case "Update_Logo_vip":
    case "Set_Logo_vip":
    case "data_vip":
    case "data_vip_bydate":
      url = "/APP_api/club.aspx";
      break;
    case "betstock_item":
    case "betstock_holding":
    case "betstock_buy":
    case "betstock_sale":
      url = "/APP_api/betstock.aspx";
      break;
    default:
      url = "/APP_api/" + action + "_APP_api.aspx";
      break;
  }
  return url;
};

export default {
  getIP,
  apiPost,
};
