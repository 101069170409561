<template>
  <div>
    <Loading :active="isLoading" :can-cancel="true">
      <img
        src="@/assets/img/main/35771931234507.564a1d2403b3a.gif"
        class="w-100"
        style="z-index: 9999"
      />
    </Loading>
    <router-view />
    <pop></pop>
    <ann></ann>
    <!-- <preview v-if="Id == '' && !flag_code_uid"></preview> -->
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Cookies from "js-cookie";
import pop from "./views/Home/pop.vue";
import ann from "./views/Home/announce.vue";
// import preview from "./views/preloading.vue";
import { mapActions } from "vuex";

export default {
  created() {
    document.title = this.$t("text_web_title");
    let s = document.createElement("meta");
    s.setAttribute("name", "description");
    s.setAttribute("content", this.$t("text_web_description"));
    document.head.appendChild(s);
    s = document.createElement("meta");
    s.setAttribute("name", "keywords");
    s.setAttribute("content", this.$t("text_web_keyword"));
    document.head.appendChild(s);
    const link = document.querySelector("[rel='icon']");
    link.setAttribute("href", "favicon.ico");
    this.getLogo();
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },

    flag_code_uid() {
      return Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
        ? false
        : true;
    },
  },
  components: {
    Loading,
    pop,
    ann,
    // preview,
  },
  methods: {
    ...mapActions(["getLogo"]),
  },
};
</script>
