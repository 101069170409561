<template>
  <div class="casino-header-container-new">
    <div
      class="d-flex justify-content-between align-items-center position-relative"
    >
      <span class="text-center club_span_big ms-0">
        {{ $t("text_club14") }}
      </span>
      <!-- <div class="casino-header-container w-50">
        <div class="casino-search-input-results w-100">
          <div class="casino-header-container-search w-100">
            <div>
              <input :placeholder="$t('slot_Search')" v-model="keyWord" />
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
            >
              <path
                fill="#7A7D8D"
                d="M13.874 12.105c.903-1.267 1.44-2.801 1.44-4.457C15.315 3.434 11.872 0 7.646 0 3.42 0 0 3.434 0 7.648c0 4.213 3.444 7.647 7.67 7.647 1.66 0 3.224-.536 4.47-1.437l2.784 2.777c.244.243.562.365.855.365.293 0 .635-.122.855-.365.488-.487.488-1.242 0-1.73l-2.76-2.8zM2.443 7.648c0-2.874 2.344-5.212 5.227-5.212 2.882 0 5.227 2.338 5.227 5.212 0 2.873-2.345 5.212-5.227 5.212-2.883-.025-5.227-2.363-5.227-5.212z"
              ></path>
            </svg>
          </div>
        </div>
      </div> -->
    </div>

    <div
      class="mt-3 w-100 p-3"
      style="background: #071d2a; border-radius: 16px"
    >
      <div style="height: 45vh; overflow-y: auto">
        <div class="new_data new_data_3 w-100 align-content-start d-grid">
          <div class="new_data-div" v-for="l in list" :key="l">
            <div class="text-end">
              <p class="fs-18">{{ $t("text_date") }}： {{l.cdate}}</p>
              <!-- <p class="fs-18 mb-0">
                {{ $t("text_king9") }}：{{ 123456798012 }}
              </p> -->
            </div>
            <div
              class="d-flex justify-content-between align-items-start"
              style="gap: 30px"
            >
              <p class="fs-18" style="flex: 1">
                {{ $t("text_club16") }}：<br />{{ l.losewin }}
              </p>
              <p class="fs-18" style="flex: 1">
                {{ $t("text_club19") }}：<br />{{ l.deposit }}
              </p>
            </div>
            <div
              class="d-flex justify-content-between align-items-start"
              style="gap: 30px"
            >
              <p class="fs-18" style="flex: 1">
                {{ $t("text_club17") }}：<br />{{ l.turnover }}
              </p>
              <p class="fs-18" style="flex: 1">
                {{ $t("text_club20") }}：<br />{{ l.withdraw }}
              </p>
            </div>
            <div
              class="d-flex justify-content-between align-items-start"
              style="gap: 30px"
            >
              <p class="fs-18 mb-0" style="flex: 1">
                {{ $t("text_club18") }}：<br />{{ l.fee }}
              </p>
              <p class="fs-18 mb-0" style="flex: 1">
                {{ $t("text_club21") }}：<br />{{ l.income }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  computed: {},
  data() {
    return {
      list: [],
    };
  },
  methods: {
    get_data: function () {
      this.begin = this.old_begin;
      this.end = this.old_end;
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "data_vip_bydate",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            clubid: localStorage.getItem("clubid"),
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          this.list = res.data;
        });
      }
    },
  },
  components: {},
  watch: {},
  created() {
    this.get_data();
  },
};
</script>
