<template>
  <div class="casino-header-container-new">
    <div
      class="d-flex justify-content-between align-items-center position-relative"
    >
      <span class="text-center club_span_big ms-0">
        {{ $t("text_club6") }}
      </span>
      <div class="casino-header-container w-50">
        <div class="casino-search-input-results w-100">
          <div class="casino-header-container-search w-100">
            <div>
              <input :placeholder="$t('slot_Search')" v-model="keyWord" />
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
            >
              <path
                fill="#7A7D8D"
                d="M13.874 12.105c.903-1.267 1.44-2.801 1.44-4.457C15.315 3.434 11.872 0 7.646 0 3.42 0 0 3.434 0 7.648c0 4.213 3.444 7.647 7.67 7.647 1.66 0 3.224-.536 4.47-1.437l2.784 2.777c.244.243.562.365.855.365.293 0 .635-.122.855-.365.488-.487.488-1.242 0-1.73l-2.76-2.8zM2.443 7.648c0-2.874 2.344-5.212 5.227-5.212 2.882 0 5.227 2.338 5.227 5.212 0 2.873-2.345 5.212-5.227 5.212-2.883-.025-5.227-2.363-5.227-5.212z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-between align-items-center position-relative mt-3 mt-1024-0 flex-1023-column mt-4"
    >
      <div class="d-flex bg_drak_coff2 member_opa">
        <div
          class="bg_linear_item c-pointer"
          @click="who_day = 1"
          :class="who_day == 1 ? 'bg_light_coff2' : ''"
        >
          <span class="club_span3">{{ $t("text_counter1") }}</span>
        </div>
        ｜
        <div
          class="bg_linear_item c-pointer"
          @click="who_day = 2"
          :class="who_day == 2 ? 'bg_light_coff2' : ''"
        >
          <span class="club_span3">{{ $t("text_counter13") }}</span>
        </div>
        <!-- ｜
        <div
          class="bg_linear_item c-pointer"
          @click="who_day = 3"
          :class="who_day == 3 ? 'bg_light_coff2' : ''"
        >
          <span class="club_span3">{{ $t("text_counter2") }}</span>
        </div> -->
      </div>

      <div class="px-2 py-3" v-if="who_day == 1 || who_day == 3">
        <div
          class="d-flex align-items-center ms-1024-5 flex-1023-column flex-1024-gap"
        >
          <div class="d-flex align-items-center">
            <span class="text-nowrap">{{ $t("text_counter3") }}</span>
            <div class="d-flex ms-3 position-relative w-1024-100">
              <img
                src="@/assets/img/d_club/img_club-cash.png"
                style="width: 40px; position: absolute; top: -10px; left: -10px"
              />
              <div class="bg_drak_coff radius-25 text-white fw-bold">
                <span class="ps-5 px-3 club_span1">{{ balance_hall }}</span>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center mt-1023-3" v-if="who_day == 1">
            <span class="text-nowrap">{{ $t("text_counter4") }}</span>
            <div class="d-flex ms-3 position-relative w-1024-100">
              <img
                src="@/assets/img/d_club/img_player-chip.png"
                style="width: 40px; position: absolute; top: -2px; left: -10px"
              />
              <div class="bg_drak_coff radius-25 text-white fw-bold">
                <span class="ps-5 px-3 club_span1">{{ coin }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-start flex-lg-column align-items-lg-center bg-table-img casino-header-container"
      style="padding-top: 0.25rem !important"
    >
      <view1
        class="w-100"
        v-if="who_day == 1"
        :keyWord="keyWord"
        @getbal="getbal"
      />
      <view2 class="w-100" v-if="who_day == 2" :keyWord="keyWord" />
      <view3
        class="w-100"
        v-if="who_day == 3"
        :keyWord="keyWord"
        @getbal="getbal"
      />
    </div>
  </div>
</template>
<script>
import view1 from "./create_counter_1.vue";
import view2 from "./create_counter_2.vue";
// import view3 from "./create_counter_3.vue";
export default {
  computed: {},
  data() {
    return {
      who_day: 1,
      keyWord: "",
      balance_hall: 0,
      coin: 0,
    };
  },
  methods: {
    getbal(master_bal, member_bal) {
      this.balance_hall = master_bal;
      this.coin = member_bal;
    },
  },
  components: { view1, view2 },
  watch: {},
  created() {},
};
</script>
