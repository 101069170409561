<template>
  <div class="">
    <div class="d-flex flex-wrap justify-content-center">
      <div
        class="col-6 col-xl-3 p-2 p-xl-3 c-pointer position-relative"
        v-for="l in 20"
        :key="l"
        @click="SetLogo(l)"
      >
        <img
          :src="require('@/assets/img/agent_big_head/' + l + '.png')"
          class="w-100"
          style="border: 1px solid #fff; border-radius: 8px"
        />

        <img
          v-if="l == big_head_img_set"
          :src="require('@/assets/img/new_img/check.png')"
          class="position-absolute"
          style="width: 30px; right: 5px; top: 5px"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      big_head_img_set: localStorage.getItem("station_logo"),
      station_logo_set: 1,
    };
  },
  computed: {},
  methods: {
    SetLogo: function (e) {
      let param = {
        action: "Update_Logo_vip",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          guid: e,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (res.status == "success") {
          this.station_logo_set = e;
          localStorage.setItem("station_logo", e);
          this.$MSG.API_popmsg(res.status.toUpperCase(), "", "success");
        } else {
          this.$MSG.API_popmsg(res.msg, "", "error");
        }
      });
    },
    get_info: function () {
      let param = {
        action: "Set_Logo_vip",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        localStorage.setItem("station_logo", res.logo);
      });
    },
  },
  watch: {
    station_logo_set: function (e) {
      this.big_head_img_set = e;
    },
  },
  created() {
    this.get_info();
  },
};
</script>
